import {Brand} from "../model/Brand";
import {Origin} from "../model/Origin";
import {getConfiguration} from "../config/config";
import {ReturnUrlValidation} from "../model/ReturnUrlValidation";

const whiteListedDomains = getConfiguration().whiteListedDomains as Array<string>;
const whiteListedDeepLinks = getConfiguration().whiteListedDeepLinks as Array<string>;
const errorWebPageLookup = new Map([
    [Brand.NEW_WORLD, getConfiguration().nwReturnUrlFailureUrl],
    [Brand.PAK_N_SAVE, getConfiguration().pnsReturnUrlFailureUrl],
]);

const errorDeepLinkLookup = new Map([
    [Brand.NEW_WORLD, getConfiguration().nwReturnUrlFailureDeepLink],
    [Brand.PAK_N_SAVE, getConfiguration().pnsReturnUrlFailureDeepLink],
]);

const fromTheWebsite = /^((http|https):\/\/)/;

export const getGoogleAnalytics = (location: string, userId: string, nwPropertyId?: string, pnsPropertyId?: string, brand?: string): string => {
    let trackingId = nwPropertyId;
    if (brand === Brand.PAK_N_SAVE) {
        trackingId = pnsPropertyId;
    }
    let info = `v=1&tid=${trackingId}&cid=${userId}&uid=${userId}&cd1=${userId}&cd25=${userId}&t=pageview&ul=en-gb&de=UTF-8&cd5=collect`;
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width && height) {
        info += `&vp=${width}x${height}`;
    }
    if (location) {
        info += `&dl=${location}`;
        if(location.indexOf("register") > -1) {
            info += `&dt=Register&dp=/register`;
        } else {
            info += `&dt=List`;
        }
    }
    const dateTime = new Date();
    info += `&cd4=${dateTime.getFullYear()}-${dateTime.getMonth() + 1}-${dateTime.getDate()}T${dateTime.toLocaleTimeString()}`;
    return info;
}

export const getRedirectUrl = (brand: string,
                               nwRedirectUrl: string | undefined,
                               pnsRedirectUrl: string | undefined,
                               isCardAdded: boolean,
                               from: string,
                               providedRedirectUrl: string | undefined): string => {
    if (isCardAdded) {
        if (from !== "" && from !== Origin.CHECK_OUT) {
            return "/"; // Back to list page after add card if redirected from manage cards
        }
    }
    if (brand === Brand.PAK_N_SAVE && pnsRedirectUrl) {
        return providedRedirectUrl || pnsRedirectUrl;
    } else if (brand === Brand.NEW_WORLD && nwRedirectUrl) {
        return providedRedirectUrl || nwRedirectUrl;
    } else {
        return "/";
    }
}

export const validateRedirectUrl = (redirectUrl: string, brand: Brand) :ReturnUrlValidation  => {
    let validation = {
        isValid: false
    } as ReturnUrlValidation;
    if (fromTheWebsite.test(redirectUrl)) {
        validation.isValid = isValidWebRedirectUrl(redirectUrl);
        if (!validation.isValid) {
            validation.failureLink = errorWebPageLookup.get(brand);
        }
    } else {
        validation.isValid = isValidMobileAppRedirectUrl(redirectUrl);
        if (!validation.isValid) {
            validation.failureLink = errorDeepLinkLookup.get(brand);
        }
    }
    return validation;
}

// https://some-test-env.newworld.co.nz/redirect-landing=page
export const isValidWebRedirectUrl = (redirectUrl: string | undefined) :boolean => {
    try {
        if (redirectUrl) {
            const hostname = new URL(redirectUrl).hostname;
            const domain = hostname.substring(hostname.indexOf(".") + 1, hostname.length);
            return whiteListedDomains.includes(domain);
        }
    } catch (error) {
        return false;
    }
    return false;
}

// newworld://some-return-page/landing
export const isValidMobileAppRedirectUrl = (redirectUrl: string | undefined) => {
    if (redirectUrl) {
        const deepLinkPrefix = redirectUrl.substring(0, redirectUrl.indexOf("//") + 2);
        return whiteListedDeepLinks.includes(deepLinkPrefix);
    }
    return false;


}