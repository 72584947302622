// @flow
import React from "react";
import {Card} from "react-bootstrap";
import HeaderLogo from "./HeaderLogo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Header = (props) => {

    return (
        <Card.Header className="cardHeader">
            <Row>
                <Col>
                    <div className=" left headerLogo">
                        <HeaderLogo/>
                    </div>
                </Col>
                <Col>
                    <div className=" right">
                        <Button data-testid="go-back-button" variant="link"
                                style={{paddingRight: 'unset'}}
                                className="headerLink" onClick={() => {props.handleRedirect(false)}}>
                            &#8592; Go Back
                        </Button>
                    </div>
                </Col>
            </Row>
            {props.postGoogleAnalytics()}
    </Card.Header>);
};

export default Header;
